import { AUTH_ACTIONS } from '../../../store/actions'

import selectUserTypesComponent from './select-user-type'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
export default {
  data() {
    return {
      signedIn: false,
      email: '',
      password: '',
      mustSelectUserType: false,
      userData: null
    }
  },
  components: {
    'userType': selectUserTypesComponent
  },
  created(){

  },
  methods: {
    signUp(){
      this.$router.push({name: 'Signup'})
    },
    async isUserSignedIn(){
      try{
        const userObj = Auth.currentAuthenticatedUser();
        this.signedIn = true;
        this.$router.push({name: 'Recalls'})
      }
      catch(err)
      {
        this.signedIn = false;
      }
    },
    async onSubmitLogin() {
      
      const isValid = await this.$refs['observer-login'].validate()
      if (!isValid) {
        this._showToast('Please check your information', { variant: 'warning' })
      } else {
        if (this.email == "") {
          this._showToast('Email is required', { variant: 'danger',title:'Error' })
        }
        else if (this.password == "") {
          this._showToast('Password is required', { variant: 'danger',title:'Error' })
        }
        else  {
          this._loadingStart()
          Auth.signIn({
            username: this.email,
            password: this.password
          })
            .then(data => {
              AmplifyEventBus.$emit('localUser', data.user);
              if (data.userConfirmed === false) {
                AmplifyEventBus.$emit('authState', 'confirmSignUp');
              } else {
                Auth.currentSession()
                .then(data1 =>{
                  //debugger
                  //console.log(data1)
                })
                .catch(err =>{
                  this._loadingEnd()
                  this._showToast(err.message, { variant: 'danger',title:'Error' })
                });
                AmplifyEventBus.$emit('authState', 'signIn');
                var UserData = [];
                UserData.full_name = data.attributes['custom:firstname'] + " " + data.attributes['custom:lastname'];
                UserData.fname = data.attributes['custom:firstname'];
                UserData.lname = data.attributes['custom:lastname'];
                UserData.email = data.attributes['email'];
                UserData.title = data.attributes['custom:title'];
                UserData.companyname = data.attributes['custom:companyname'];
                UserData.phoneno = data.attributes['phone_number'];
                localStorage.setItem('storedData', data.attributes['custom:firstname'] + " " + data.attributes['custom:lastname'])
                var userD = [];
                userD[0] = UserData.full_name;
                userD[1] = UserData.fname;
                userD[2] = UserData.lname;
                userD[3] = UserData.email;
                userD[4] = UserData.title;
                userD[5] = UserData.companyname;
                userD[6] = UserData.phoneno;
                localStorage.setItem("userD", JSON.stringify(userD));
                localStorage.setItem('userId', data.username)
                this.$store.commit('UPDATE_USER_PROFILE', UserData)
                this.$store.dispatch(AUTH_ACTIONS.LOGIN_LITE, {
                  client_id: 'web-client',
                  grant_type: 'password',
                  email: this.email,
                  password: this.password,
                  refresh_token: {access_token:data.signInUserSession.accessToken.jwtToken,refresh_token: data.signInUserSession.accessToken.jwtToken},
                  login_as: '',
                  login_as_org_id: 0
              })
                this.$router.push({name: 'Recalls'})
              }
              return data;
            })
            .catch(err => {
              if (err.message == "Cannot read property 'auth' of undefined") {
                this.$router.push({name: 'Recalls'})
              }
              else  
              {
                this._loadingEnd()
                this._showToast(err.message, { variant: 'danger',title:'Error' })
              }
            });
        }
         
      }
    },
    IsSignedIn(){  
      AmplifyEventBus.$on('authState', info=>{
        if(info === 'signIn'){
          this.isUserSignedIn();
          this.$router.push({name: 'Recalls'})
        }
        else  {
          this.signedIn = false;
        }
      })
    },
    confirmSignUp() {
      Auth.confirmSignUp(this.email, this.password).then(data => {
        AmplifyEventBus.$emit('authState', 'signIn')
        return data // 'SUCCESS'
      })
        .catch(err => {
        });
    },
    hidePopup() {
      this.mustSelectUserType = false
    }
  },
  computed: {
  },
  beforeMount(){
 },
  watch: {
  }
}